import React, { useEffect, useState } from "react";
import { GoTriangleDown } from "react-icons/go";
import NavTitle from "./NavTitle";
import { states } from "country-cities";
import { cities } from "country-cities";

const State = ({ filterState }) => {
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [errState, setErrState] = useState("");
  const [errCity, setErrCity] = useState("");
  const [genericError, setGenericError] = useState("");
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [isoState, setIsoState] = useState("");

  useEffect(() => {
    const fetchedStates = states.getByCountry("IN");
    setAllStates(fetchedStates);
  }, []);

  useEffect(() => {
    const fetchedCities = cities.getByState(isoState, "IN");
    setAllCities(fetchedCities);
  }, [isoState]);

  const handleStateChange = (e) => {
    const selectedIsoState = e.target.value.split(":")[0];
    const selectedState = e.target.value.split(":")[1];

    setState(selectedState);
    setCity("");
    setErrState("");
    setErrCity("");
    setGenericError("");
    setIsoState(selectedIsoState);
  };

  const handleCityChange = (e) => {
    const selectedCity = e.target.value.split(":")[0];
    setCity(selectedCity);
    setErrCity("");
    setGenericError("");
  };

  return (
    <div className="">
      <NavTitle title="Swap by Location" />
      <div className="flex items-center gap-2 md:gap-6 mb-4">
        <div className="flex items-center gap-2 text-base text-[#767676] relative">
          <select
            onChange={(e) => {
              filterState(e.target.value.split(":")[1]);
            }}
            id="countries"
            className="w-[17rem] border-[1px] border-gray-200 py-1 px-4 cursor-pointer text-primeColor text-base block dark:placeholder-gray-400 appearance-none focus-within:outline-none focus-visible:border-primeColor"
          >
            <option value="">State</option>
            {allStates.map((state) => (
              <option
                key={state.isoCode}
                value={`${state.isoCode}:${state.name}`}
              >
                {state.name}
              </option>
            ))}
          </select>
          <span className="absolute text-sm right-2 md:right-4 top-2.5">
            <GoTriangleDown />
          </span>
        </div>
      </div>

      {/* <div className="flex items-center gap-2 md:gap-6">
        <div className="flex items-center gap-2 text-base text-[#767676] relative">
          <select
            onChange={handleCityChange}
            id="countries"
            className="w-[17rem] border-[1px] border-gray-200 py-1 px-4 cursor-pointer text-primeColor text-base block dark:placeholder-gray-400 appearance-none focus-within:outline-none focus-visible:border-primeColor"
          >
            <option value="">City</option>
            {allCities.map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>
          <span className="absolute text-sm right-2 md:right-4 top-2.5">
            <GoTriangleDown />
          </span>
        </div>
      </div> */}
    </div>
  );
};

export default State;
