import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const FilterCategoryList = ({ title }) => {
  const navigate = useNavigate();
  const productData = useSelector(
    (state) => state.productData?.productData?.productData?.data
  );

  const Filtercategory = productData?.filter((x) => x.category == title);

  return (
    <ul className="flex flex-col gap-4 text-sm lg:text-base my-2 text-[#767676]">
      {Filtercategory?.map((item) => (
        <li
          onClick={() => navigate(`/product/${item._id}`)}
          key={item._id}
          className="border-b-[1px] cursor-pointer border-b-[#F0F0F0] pb-2 flex items-center gap-2"
        >
          <div className="flex items-center gap-2">
            <img
              src={`${process.env.REACT_APP_BACKEND_URL}/images/${item._id}-0`}
              alt="baby fabric shoes"
              width="70px"
              height="70px"
              className="showcase-img rounded-md"
            />

            <div className="flex flex-col justify-center-center">
              <h4 className="text-[.875rem]">{item.title}</h4>
              <h4
                className="text-[.875rem] text-black font-medium opacity-80"
                style={{ lineHeight: "1" }}
              >
                {item.category}
              </h4>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default FilterCategoryList;
