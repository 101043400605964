import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchAdminProduct = createAsyncThunk("fetchAdminProduct", async (token) => {
  try {
    const response = await axios.get(`https://api.swapit.co.in/admin`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching admin product data:", error);
    throw error;
  }
});

const adminProductSlice = createSlice({
  name: "adminProdt",
  initialState: {
    isLoading: false,
    data: null,
    error: null, // Changed from isError to error
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminProduct.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Reset error when starting to fetch
      })
      .addCase(fetchAdminProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchAdminProduct.rejected, (state, action) => {
        console.error("Error fetching admin product data:", action.error.message);
        state.isLoading = false;
        state.error = action.error.message; // Store the error message consistently
      });
  },
});

export default adminProductSlice.reducer;
