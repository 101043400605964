import React from "react";
import { BsSuitHeartFill } from "react-icons/bs";
import { MdOutlineLabelImportant } from "react-icons/md";
import Image from "../../designLayouts/Image";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
const Product = ({ item, id, title, category }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const token = useSelector((store) => store.auth.token);
  const user = useSelector((store) => store.auth.user);
  const handelAddToWishlist = async () => {
    try {
      const authToken = `Bearer ${token}`;
      const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/wish/create`;

      const response = await axios.post(
        apiUrl,
        {
          productID: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
        }
      );

      if (response.status === 301) {
        const redirectedUrl = response.headers.location;
        if (redirectedUrl) {
          const redirectedResponse = await axios.post(
            redirectedUrl,
            {
              productID: id,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: authToken,
              },
            }
          );

          if (!redirectedResponse.status === 200) {
            throw new Error(`HTTP error! Status: ${redirectedResponse.status}`);
          }

          toast({
            title: "Added to wishlist",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          throw new Error("Redirected URL not found");
        }
      } else if (!response.status === 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        toast({
          title: "Added to wishlist",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error.message);
      toast({
        title: "Error adding to wishlist",
        description: "Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="w-full relative group shadow">
      <div className="w-full h-[200px] relative overflow-y-hidden ">
        <div>
          <Image
            className="w-full h-[200px] object-contain"
            imgSrc={`${process.env.REACT_APP_BACKEND_URL}/images/${id}-0`}
          />
        </div>

        <div className="w-full h-24 absolute bg-white -bottom-[130px] group-hover:bottom-0 duration-700">
          <ul className="w-full h-full flex flex-col items-end justify-center gap-2 font-titleFont px-2 border-l border-r">
            <li
              onClick={() => {
                if (user.email === undefined) {
                  navigate("/signin");
                } else {
                  navigate(`/product/${id}`);
                  localStorage.setItem("singelProduct", JSON.stringify(item));
                }
              }}
              className="text-[#767676] hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full"
            >
              View Details
              <span className="text-lg">
                <MdOutlineLabelImportant />
              </span>
            </li>

            <li
              onClick={() => handelAddToWishlist()}
              className="text-[#767676] hover:text-primeColor text-sm font-normal border-b-[1px] border-b-gray-200 hover:border-b-primeColor flex items-center justify-end gap-2 hover:cursor-pointer pb-1 duration-300 w-full"
            >
              Add to Wish List
              <span>
                <BsSuitHeartFill />
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full py-4 flex flex-col gap-1 border-[1px] border-t-0 px-4 bg-white">
        <div className="flex items-center justify-between font-titleFont">
          <h2 className="text-md text-primeColor font-bold h-[23px] w-[50%] overflow-hidden">
            {title}
          </h2>
          <p className="text-[#767676] text-[14px] h-[23px] w-[100%] overflow-hidden flex justify-end">
            {category}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Product;
