import React, { useEffect, useState } from "react";
import Category from "./shopBy/Category";
import State from "./shopBy/State";

const ShopSideNav = ({filterState}) => {

  

  return (
    <div className="w-full flex flex-col gap-6">
      <State filterState={filterState}/>
      <Category />
    </div>
  );
};

export default ShopSideNav;
