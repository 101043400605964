import { Spinner, useToast } from "@chakra-ui/react";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const Report = ({ setReport }) => {
  const token = useSelector((store) => store.auth.token);
  const id = useParams();
  const productId = id._id;
  const [message, setMessage] = useState("");
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const handleSuccess = () => {
    toast({
      title: "Report submitted successfully!",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleError = (errorMessage) => {
    toast({
      title: `Error: ${errorMessage}`,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `https://api.swapit.co.in/product/report/${productId}`,
        {
          comment: message,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Report submitted successfully:", response.data);
      handleSuccess();
    } catch (error) {
      console.error("Error submitting report:", error);
      handleError(error.message);
    }
    setTimeout(() => {
      setReport(false);
    }, 500);
    setLoading(true);
  };

  return (
    <div
      className="h-screen w-full fixed top-0 left-0 flex justify-center items-center overflow-auto"
      style={{ zIndex: 999 }}
    >
      <div className="shadow-2xl bg-white h-[90vh] md:w-[50%] sm:w-[90%] overflow-auto relative p-2 rounded-lg">
        <div className="absolute top-4 right-4">
          <IoClose
            onClick={() => setReport(false)}
            className="text-2xl cursor-pointer"
          />
        </div>
        <div className="max-w-xl mx-auto mt-16 flex w-full flex-col border rounded-lg bg-white p-8">
          <h2 className="title-font mb-1 flex text-lg font-medium text-gray-900">
            Report Product
          </h2>
          <p className="mb-5 leading-relaxed text-gray-600">
            If you had any issue with this product, please share with us!
          </p>

          <div className="mb-4">
            <label
              htmlFor="message"
              className="text-sm leading-7 text-gray-600"
            >
              Please Describe your issue
            </label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)} // Update state with textarea value
              className="h-32 w-full resize-none rounded border border-gray-300 bg-white py-1 px-3 text-base leading-6 text-gray-700 outline-none transition-colors duration-200 ease-in-out focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200"
            ></textarea>
          </div>
          <button
            onClick={handleSubmit}
            className="rounded border-0 bg-red-500 py-2 px-6 text-lg text-white hover:bg-red-600 focus:outline-none"
          >
            {loading ? <Spinner size="sm" /> : "Report"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Report;
