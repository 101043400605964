import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import Image from "../designLayouts/Image";

const Banner = () => {
  const [dotActive, setDotActive] = useState(0);

  const customPaging = (i) => (
    <div
      style={{
        width: "25px",
        color: i === dotActive ? "#262626" : "transparent",
        borderRight: `3px ${i === dotActive ? "#262626" : "white"} solid`,
        cursor: "pointer",
        fontSize: "12px",
      }}
    >
      0{i + 1}
    </div>
  );

  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => setDotActive(next),
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: false,
          customPaging: customPaging,
        },
      },
    ],
  };

  const bannerImages = [
    "https://www.artsample.com/blog/wp-content/uploads/sites/71/2019/10/buy-living-room-furniture.jpg",
    "https://www.artsample.com/blog/wp-content/uploads/sites/71/2019/10/buy-living-room-furniture.jpg",
  ];

  return (
    <div className="w-full bg-[#f3f3f3] ">
      <Slider {...settings}>
        {bannerImages.map((imgSrc, index) => (
          <Link key={index} to="/shop">
            <div>
              <Image
                className="md:h-[280px] sm:h-full w-full object-cover"
                imgSrc={imgSrc}
              />
            </div>
          </Link>
        ))}
      </Slider>
    </div>
  );
};

export default Banner;
