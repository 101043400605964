import * as types from "./ActionTypes";
import axios from "axios";

export const signin = (payload, toast, navigate) => async (dispatch) => {
  try {
    console.log("Sending sign-in request with payload:", payload);

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/signin`,
      payload
    );

    console.log("Sign-in response:", response.data);

    dispatch({ type: types.SIGNIN_SUCCESS, payload: response.data });

    toast({
      title: response.data.msg,
      status: "success",
      duration: 1000,
      isClosable: true,
    });

    if (response.data.user.administration === "") {
      navigate("/");
    } else {
      navigate("/profile");
    }
  } catch (error) {
    console.error("Error during sign-in:", error);

    dispatch({ type: types.SIGNIN_FAILURE });

    toast({
      title: "Error signing in. Please check your credentials.",
      status: "error",
      duration: 1000,
      isClosable: true,
    });
  }
};



export const signup = (payload, toast) => async (dispatch) => {
  dispatch({ type: types.SIGNUP_REQUEST });

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/signup`,
      payload
    );

    dispatch({ type: types.SIGNUP_SUCCESS });

    toast({
      title: response.data.msg,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  } catch (error) {
    dispatch({ type: types.SIGNUP_FAILURE });

    toast({
      title: error.response.data.msg,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
  }
};



export const logout = (toast, navigate) => async (dispatch) => {
  try {
    dispatch({ type: types.SIGNOUT_REQUEST });
    dispatch({ type: types.SIGNOUT_SUCCESS });

    toast({
      title: "Account Logout",
      status: "success",
      duration: 3000,
      isClosable: true,
    });

    navigate("/");
  } catch (error) {
    dispatch({ type: types.SIGNOUT_FAILURE });
  }
};
