import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { FaSearch } from "react-icons/fa";
import Flex from "../../designLayouts/Flex";
import { MdFavoriteBorder } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { VscSettings } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { categoryData } from "../../../constants";

const HeaderBottom = ({ cate }) => {
  const token = useSelector((store) => store.auth.token);
  const productData = useSelector(
    (state) => state.productData?.productData?.productData?.data
  );


  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const wishListData = useSelector((store) => store.wishList?.data?.data);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const filtered = productData?.filter((item) =>
      item.title?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchQuery]);

  return (
    <div className="w-full bg-[#F5F5F3] relative">
      <div className="max-w-container mx-auto">
        <Flex className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full px-4 pb-4 lg:pb-0 h-full lg:h-24">
          <div
            id="flex-center"
            ref={ref}
            className="flex h-14 justify-between items-center  text-primeColor"
          >
            <div
              onClick={() => setShow(!show)}
              className="flex items-center gap-2 cursor-pointer"
            >
              <VscSettings className="w-5 h-5" />
              <p className="text-[14px] font-normal">Swap by Category</p>

              {show && (
                <motion.ul
                  initial={{ z: 50, opacity: 0 }}
                  animate={{ z: 0, opacity: 1 }}
                  transition={{ duration: 1 }}
                  className="absolute top-[4rem] z-50 bg-primeColor w-auto text-[#767676] h-auto p-4 pb-6"
                >
                  {categoryData.map((x) => {
                    return (
                      <li
                        key={x.id}
                        onClick={() => {
                          navigate("/");
                          cate(x.title);
                        }}
                        className="text-gray-400 px-4 py-1 border-b-[1px] border-b-gray-400 hover:border-b-white hover:text-white duration-300 cursor-pointer"
                      >
                        {x.title}
                      </li>
                    );
                  })}
                </motion.ul>
              )}
            </div>

            <div
              id="display-none"
              className="flex gap-4  lg:mt-0 items-center cursor-pointer relative "
            >
              <div onClick={() => navigate("/profile")} className="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.893"
                  height="22.791"
                  viewBox="0 0 24.893 22.791"
                >
                  <g id="log-out" transform="translate(-1840.721 -24.4)">
                    <g id="Layer_2" transform="translate(1841.122 24.8)">
                      <g
                        id="Group_7"
                        data-name="Group 7"
                        transform="translate(0 0)"
                      >
                        <g
                          id="Group_2"
                          data-name="Group 2"
                          transform="translate(8.622 12.84)"
                        >
                          <path
                            id="Path_2"
                            data-name="Path 2"
                            d="M32.166,37.73c-.012.066-.047.273-.371.234a.323.323,0,0,1-.246-.324,3.855,3.855,0,0,0-3.092-3.763,3.811,3.811,0,0,0-2.928.632,3.735,3.735,0,0,0-1.589,2.483c-.02.129-.027.254-.039.371a2.628,2.628,0,0,1-.035.332.306.306,0,0,1-.386.273.327.327,0,0,1-.25-.34A4.389,4.389,0,0,1,24.3,34.759a4.448,4.448,0,0,1,2.9-1.562h.547a4.4,4.4,0,0,1,4.415,3.83A2.385,2.385,0,0,1,32.166,37.73Z"
                            transform="translate(-23.23 -33.196)"
                            fill="#000"
                            stroke="#000"
                            strokeWidth="0.8"
                          ></path>
                        </g>
                        <g
                          id="Group_4"
                          data-name="Group 4"
                          transform="translate(10.141 4.743)"
                        >
                          <g id="Group_3" data-name="Group 3">
                            <path
                              id="Path_3"
                              data-name="Path 3"
                              d="M32.187,16.843a2.967,2.967,0,1,0-2.143,5.063h0a2.985,2.985,0,0,0,2.135-.874,2.911,2.911,0,0,0,.855-2.073A2.942,2.942,0,0,0,32.187,16.843Zm-2.1,4.415a2.342,2.342,0,0,1-1.663-.687,2.3,2.3,0,0,1-.671-1.628,2.363,2.363,0,0,1,.7-1.655,2.333,2.333,0,0,1,1.632-.679,2.368,2.368,0,0,1,2.319,2.346A2.315,2.315,0,0,1,30.091,21.258Z"
                              transform="translate(-27.12 -15.98)"
                              fill="#000"
                              stroke="#000"
                              strokeWidth="0.8"
                            ></path>
                          </g>
                        </g>
                        <g
                          id="Group_5"
                          data-name="Group 5"
                          transform="translate(2.371)"
                        >
                          <path
                            id="Path_4"
                            data-name="Path 4"
                            d="M28.923,15.378a10.989,10.989,0,0,1-8.471,10.154,10.58,10.58,0,0,1-2.6.289A11,11,0,0,1,7.327,17.654a3.434,3.434,0,0,1-.105-.468.3.3,0,0,1,.273-.379.108.108,0,0,1,.035,0,.349.349,0,0,1,.3.293,9.244,9.244,0,0,0,.832,2.346,10.324,10.324,0,0,0,7.074,5.52,9.63,9.63,0,0,0,2.994.207,10.385,10.385,0,0,0,9.307-7.9,9.844,9.844,0,0,0,.277-3,10.368,10.368,0,0,0-10.33-9.814h-.023a10.385,10.385,0,0,0-10.123,8.1c-.051.223-.164.269-.277.289a.288.288,0,0,1-.3-.144c-.035-.051-.066-.125-.023-.308A11.021,11.021,0,0,1,15.8,4.049,10.608,10.608,0,0,1,18.8,3.865a11.011,11.011,0,0,1,9.943,8.8A11.633,11.633,0,0,1,28.923,15.378Z"
                            transform="translate(-7.217 -3.83)"
                            fill="#000"
                            stroke="#000"
                            strokeWidth="0.8"
                          ></path>
                        </g>
                        <g
                          id="Group_6"
                          data-name="Group 6"
                          transform="translate(0 9.17)"
                        >
                          <path
                            id="Path_5"
                            data-name="Path 5"
                            d="M7.775,29.37c-.5.511-1.011,1.023-1.526,1.526a.321.321,0,0,1-.226.094.313.313,0,0,1-.2-.543c.1-.1.2-.2.3-.3l.137-.133c.02-.02.059-.055.059-.055l.5-.492H1.513a.341.341,0,0,1-.355-.215.317.317,0,0,1,.043-.281.362.362,0,0,1,.289-.129c.554,0,1.113,0,1.667,0H6.912l-.632-.558c-.051-.047-.1-.094-.152-.137-.109-.1-.211-.191-.3-.289a.325.325,0,0,1-.1-.277.315.315,0,0,1,.164-.223.284.284,0,0,1,.351.051c.578.562,1.081,1.066,1.538,1.534A.28.28,0,0,1,7.775,29.37Z"
                            transform="translate(-1.144 -27.32)"
                            fill="#000"
                            stroke="#000"
                            strokeWidth="0.8"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <Link to="/cart">
                <div className="relative">
                  <MdFavoriteBorder className="text-[22.791px]" />

                  <span className="absolute font-titleFont top-3.5 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                    {wishListData?.length === null ? "0" : wishListData?.length}
                  </span>
                </div>
              </Link>
            </div>
          </div>

          <div
            className="relative w-full lg:w-[600px] h-[50px] text-base  text-primeColor bg-white flex items-center gap-2 justify-between pl-6 rounded-sm"
            style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px" }}
          >
            <input
              className="flex-1 h-full outline-none placeholder:text-[#C4C4C4] placeholder:text-[14px]"
              type="text"
              onChange={handleSearch}
              value={searchQuery}
              placeholder="Search your products here"
            />
            <div className="w-12 h-full flex items-center justify-center rounded-full">
              <FaSearch className="text-black h-5 w-5" />
            </div>
            {searchQuery && (
              <div className="w-full mx-auto h-96 bg-white top-16 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer">
                {filteredProducts?.map((item) => (
                  <div
                    key={item._id}
                    onClick={() => {
                      window.location.href = `/product/${item._id}`;
                    }}
                    className="max-w-[600px] h-28 bg-gray-100 mb-3 flex items-center gap-3"
                  >
                    <img
                      className="max-w-[80px] object-contain overflow-hidden"
                      src={`${process.env.REACT_APP_BACKEND_URL}/images/${item._id}-0`}
                      alt="productImg"
                    />
                    <div className="flex flex-col gap-1">
                      <p className="font-semibold text-lg">{item.title}</p>
                      <p className="text-xs">{item.description}</p>
                      <p className="text-sm">
                        State:{" "}
                        <span className="text-primeColor font-semibold">
                          {item.state}
                        </span>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div
            id="display-show"
            className="flex gap-4 mt-2 lg:mt-0 items-center pr-6 cursor-pointer relative "
          >
            <div
              onClick={() => {
                navigate("/profile");
              }}
              className="flex"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.893"
                height="22.791"
                viewBox="0 0 24.893 22.791"
              >
                <g id="log-out" transform="translate(-1840.721 -24.4)">
                  <g id="Layer_2" transform="translate(1841.122 24.8)">
                    <g
                      id="Group_7"
                      data-name="Group 7"
                      transform="translate(0 0)"
                    >
                      <g
                        id="Group_2"
                        data-name="Group 2"
                        transform="translate(8.622 12.84)"
                      >
                        <path
                          id="Path_2"
                          data-name="Path 2"
                          d="M32.166,37.73c-.012.066-.047.273-.371.234a.323.323,0,0,1-.246-.324,3.855,3.855,0,0,0-3.092-3.763,3.811,3.811,0,0,0-2.928.632,3.735,3.735,0,0,0-1.589,2.483c-.02.129-.027.254-.039.371a2.628,2.628,0,0,1-.035.332.306.306,0,0,1-.386.273.327.327,0,0,1-.25-.34A4.389,4.389,0,0,1,24.3,34.759a4.448,4.448,0,0,1,2.9-1.562h.547a4.4,4.4,0,0,1,4.415,3.83A2.385,2.385,0,0,1,32.166,37.73Z"
                          transform="translate(-23.23 -33.196)"
                          fill="#000"
                          stroke="#000"
                          strokeWidth="0.8"
                        ></path>
                      </g>
                      <g
                        id="Group_4"
                        data-name="Group 4"
                        transform="translate(10.141 4.743)"
                      >
                        <g id="Group_3" data-name="Group 3">
                          <path
                            id="Path_3"
                            data-name="Path 3"
                            d="M32.187,16.843a2.967,2.967,0,1,0-2.143,5.063h0a2.985,2.985,0,0,0,2.135-.874,2.911,2.911,0,0,0,.855-2.073A2.942,2.942,0,0,0,32.187,16.843Zm-2.1,4.415a2.342,2.342,0,0,1-1.663-.687,2.3,2.3,0,0,1-.671-1.628,2.363,2.363,0,0,1,.7-1.655,2.333,2.333,0,0,1,1.632-.679,2.368,2.368,0,0,1,2.319,2.346A2.315,2.315,0,0,1,30.091,21.258Z"
                            transform="translate(-27.12 -15.98)"
                            fill="#000"
                            stroke="#000"
                            strokeWidth="0.8"
                          ></path>
                        </g>
                      </g>
                      <g
                        id="Group_5"
                        data-name="Group 5"
                        transform="translate(2.371)"
                      >
                        <path
                          id="Path_4"
                          data-name="Path 4"
                          d="M28.923,15.378a10.989,10.989,0,0,1-8.471,10.154,10.58,10.58,0,0,1-2.6.289A11,11,0,0,1,7.327,17.654a3.434,3.434,0,0,1-.105-.468.3.3,0,0,1,.273-.379.108.108,0,0,1,.035,0,.349.349,0,0,1,.3.293,9.244,9.244,0,0,0,.832,2.346,10.324,10.324,0,0,0,7.074,5.52,9.63,9.63,0,0,0,2.994.207,10.385,10.385,0,0,0,9.307-7.9,9.844,9.844,0,0,0,.277-3,10.368,10.368,0,0,0-10.33-9.814h-.023a10.385,10.385,0,0,0-10.123,8.1c-.051.223-.164.269-.277.289a.288.288,0,0,1-.3-.144c-.035-.051-.066-.125-.023-.308A11.021,11.021,0,0,1,15.8,4.049,10.608,10.608,0,0,1,18.8,3.865a11.011,11.011,0,0,1,9.943,8.8A11.633,11.633,0,0,1,28.923,15.378Z"
                          transform="translate(-7.217 -3.83)"
                          fill="#000"
                          stroke="#000"
                          strokeWidth="0.8"
                        ></path>
                      </g>
                      <g
                        id="Group_6"
                        data-name="Group 6"
                        transform="translate(0 9.17)"
                      >
                        <path
                          id="Path_5"
                          data-name="Path 5"
                          d="M7.775,29.37c-.5.511-1.011,1.023-1.526,1.526a.321.321,0,0,1-.226.094.313.313,0,0,1-.2-.543c.1-.1.2-.2.3-.3l.137-.133c.02-.02.059-.055.059-.055l.5-.492H1.513a.341.341,0,0,1-.355-.215.317.317,0,0,1,.043-.281.362.362,0,0,1,.289-.129c.554,0,1.113,0,1.667,0H6.912l-.632-.558c-.051-.047-.1-.094-.152-.137-.109-.1-.211-.191-.3-.289a.325.325,0,0,1-.1-.277.315.315,0,0,1,.164-.223.284.284,0,0,1,.351.051c.578.562,1.081,1.066,1.538,1.534A.28.28,0,0,1,7.775,29.37Z"
                          transform="translate(-1.144 -27.32)"
                          fill="#000"
                          stroke="#000"
                          strokeWidth="0.8"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <Link to="/cart">
              <div className="relative">
                <MdFavoriteBorder className="text-[22.791px]" />

                <span className="absolute font-titleFont top-3.5 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                  {wishListData?.length === null ? "0" : wishListData?.length}
                </span>
              </div>
            </Link>
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default HeaderBottom;
