import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { RiExchangeBoxFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
const SwapPopup = ({ setdisplayPopUp }) => {
  const token = useSelector((store) => store.auth.token);
  const toast = useToast();
  const productData = useSelector(
    (state) => state.productData?.productData?.productData?.data
  );
  const [localData, setLocalData] = useState();
  const user = useSelector((store) => store.auth.user);
  useEffect(() => {
    const storedData = localStorage?.getItem("singelProduct");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setLocalData(parsedData);
    }
  }, []);
  const toProductID = localData?._id;
  const filteredData = productData?.filter((x) => x.userID === user._id);

  const handelClick = async (id) => {
    const payload = {
      toProductID: toProductID,

      fromProductID: id, //[id]
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/exchange/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Exchange success:", response.data);
      toast({
        title: "Exchange Successful",
        description: "Your product has been successfully exchanged.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error creating exchange:", error);
      toast({
        title: "Exchange Error",
        description:
          "There was an error during the exchange. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <div
      className="py-14 max-h-[60vh] overflow-auto px-24 sm:px-12  rounded-3xl  bg-white w-full"
      style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
    >
      <div
        aria-label="header"
        className="flex items-center justify-between space-x-2"
      >
        <RiExchangeBoxFill className="text-[33px]" />

        <h3 className="font-medium text-lg tracking-tight text-gray-900 leading-tight">
          Swap Your Items
        </h3>

        <div className="inline-flex cursor-pointer items-center shrink-0 justify-center w-8 h-8 rounded-full text-white bg-gray-900 focus:outline-none">
          <IoClose onClick={() => setdisplayPopUp(false)} />
        </div>
      </div>
      <div aria-label="content" className="mt-9 grid gap-2.5">
        {/* 
        ------ Users Product Start
        */}
        {filteredData?.map((x) => {
          return (
            <div
              onClick={() => handelClick(x._id, x.userID)}
              className="flex gap-2 cursor-pointer items-center space-x-4 px-10 py-2 bg-gray-100"
            >
              <div className="checkbox">
                <input type="checkbox" name="" id="" />
              </div>
              <span className="flex items-center justify-center w-10 h-10 shrink-0 rounded-full bg-white text-gray-900">
                <img
                  loading="lazy"
                  title="img"
                  alt="image"
                  width="100"
                  height="100"
                  className="rounded-sm"
                  src={`${process.env.REACT_APP_BACKEND_URL}/images/${x._id}-0`}
                />
              </span>

              <div className="flex flex-col flex-1">
                <h3 className="text-sm font-medium">{x.title}</h3>
              </div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 shrink-0"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M9 6l6 6l-6 6"></path>
              </svg>
            </div>
          );
        })}

        <div className="flex justify-end">
          <button className="mb-6 bg-black text-white px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal lg:mb-0">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default SwapPopup;
