export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  {
    _id: 1002,
    title: "Store",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "About",
    link: "/about",
  },
  {
    _id: 1004,
    title: "Contact",
    link: "/contact",
  },
];

export const categoryData = [
  {
    id: 12121,
    title: "Accessories",
  },
  {
    id: 13543,
    title: "Furniture",
  },
  {
    id: 342343,
    title: "Electronics",
  },
  {
    id: 5745,
    title: "Clothes",
  },
  {
    id: 8783548,
    title: "Bags",
  },
  {
    id: 754538,
    title: "Home appliances",
  },

  {
    id: 847848,
    title: "Almari",
  },
  {
    id: 87845,
    title: "Dresin Table",
  },
];
