import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import About from "../pages/About/About";
import LoginPage from "../pages/Account/Signin";
import Register from "../pages/Account/Signup";
import Cart from "../pages/Cart/Cart";
import Contact from "../pages/Contact/Contact";
import Home from "../pages/Home/Home";
import ProductDetails from "../pages/ProductDetails/ProductDetails";
import Shop from "../pages/Shop/Shop";
import Profile from "../pages/ProfilePage/Profile";
import PrivetRoute from "./PrivetRoute";
import AdminRoute from "./AdminRoute";
import Admin from "../pages/Admin/Admin";
import ViewUserDetails from "../pages/ProfilePage/ProfileContent/ViewUserDetails";
import HeaderSEO from "../components/HeaderSEO";
import CategoryPage from "../pages/CategoryPage/CategoryPage";
const AllRoutes = ({ data }) => {
  return (
    <Routes>
      {/* ==================== Header Navlink Start here =================== */}
      <Route
        path="/"
        element={
          <>
            <HeaderSEO />
            <Home data={data} />
          </>
        }
      />
      <Route path="/shop" element={<Shop />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      {/* ==================== Header Navlink End here ===================== */}
      <Route path="/product/:_id" element={<ProductDetails />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/view/:_id" element={<ViewUserDetails />} />
      <Route path="/category/:_id" element={<CategoryPage />} />

      {/* ==================== Login And Signin ===================== */}
      <Route path="/signin" element={<LoginPage />} />
      <Route path="/signup" element={<Register />} />
      <Route />
      {/* ==================== Profile ===================== */}
      <Route
        path="/profile"
        element={
          <PrivetRoute>
            <Profile />
          </PrivetRoute>
        }
      />

      <Route
        path="/admin"
        element={
          <AdminRoute>
            <Admin />
          </AdminRoute>
        }
      />
    </Routes>
  );
};

export default AllRoutes;
