import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signin } from "../../redux/AuthReducer/Action";
import { useToast, Spinner } from "@chakra-ui/react";
const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // New state for loading
  const [error, setError] = useState("");
  const toast = useToast();

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setError("");
  };

  const handelSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      let userCredentials = {
        email,
        password,
      };
      await dispatch(signin(userCredentials, toast, navigate));
    } catch (error) {
      console.error("Error during login:", error.message);
      setError("An error occurred during login. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-container mx-auto px-4 py-8">
      <form className="pb-6">
        <h1 className="font-titleFont text-center font-semibold text-3xl">
          Sign In
        </h1>
        <div className="w-full max-w-[500px] mx-auto py-6 flex flex-col gap-6">
          <div>
            <p className="text-base font-titleFont font-semibold px-2">Email</p>
            <input
              onChange={handleEmail}
              value={email}
              className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
              type="email"
              placeholder="Enter your email here"
            />
          </div>
          <div>
            <p className="text-base font-titleFont font-semibold px-2">
              Password
            </p>
            <input
              onChange={handlePassword}
              value={password}
              className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
              type="password"
              placeholder="Enter your password here"
            />
          </div>

          {error && (
            <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
              <span className="text-sm italic font-bold">!</span>
              {error}
            </p>
          )}

          <div className="flex gap-3">
            <button
              onClick={handelSubmit}
              className="w-44 bg-primeColor rounded-md text-gray-200 h-10 font-titleFont text-base tracking-wide font-semibold hover:bg-black hover:text-white duration-200"
              disabled={loading}
            >
              {loading ? <Spinner size="sm" /> : "Sign In"}
            </button>

            <Link to="/signup">
              <button
                className="w-44 bg-primeColor rounded-md text-gray-200 h-10 font-titleFont text-base tracking-wide font-semibold hover:bg-black hover:text-white duration-200"
                disabled={loading}
              >
                Register
              </button>
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
