import React from "react";
import { useNavigate } from "react-router-dom";

const ProductsGrig = () => {
  const navigate = useNavigate();
  const arr = [
    {
      title: "Appliances",
      img: "https://media.istockphoto.com/id/1211554164/photo/3d-render-of-home-appliances-collection-set.jpg?s=612x612&w=0&k=20&c=blm3IyPyZo5ElWLOjI-hFMG-NrKQ0G76JpWGyNttF8s=",
    },
    {
      title: "Furniture",
      img: "https://www.bria.com.ph/wp-content/uploads/2022/07/Wooden-Furniture-Pieces.png",
    },
    {
      title: "Accessories",
      img: "https://www.crmjewelers.com/cdn/shop/articles/hublot-watches-celebrities.jpg?v=1668268978",
    },
    {
      title: "Electronics",
      img: "https://cdn.mos.cms.futurecdn.net/DDtSXxoZGwjJrUhVWkzCBK.jpg",
    },
  ];

  return (
    <section
      id="Projects"
      className="w-full px-2 py-4 mx-auto grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 justify-items-center  mt-2 mb-2 gap-4"
    >
      {arr.map((x) => {
        return (
          <div
            onClick={() => navigate(`/category/${x.title}`)}
            key={Math.random()}
            className="w-full cursor-pointer bg-white shadow-sm  rounded-sm duration-500 hover:scale-105 hover:shadow-md"
          >
            <a>
              <img
                src={x.img}
                alt="Product"
                className="h-48 w-full object-cover rounded-t-md"
              />
              <div className="px-4 py-2 w-full flex flex-col items-center justify-center">
                <p className="text-lg font-bold text-black truncate block capitalize">
                  {x.title}
                </p>
              </div>
            </a>
          </div>
        );
      })}
    </section>
  );
};

export default ProductsGrig;
