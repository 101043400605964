import React from "react";
import Slider from "react-slick";
import Product from "../Products/Product";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";

const SliderProduct = ({ data }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="w-full pb-6">
      <Slider {...settings}>
        {data?.map((x) => (
          <div key={Math.random()} className="px-2">
            {
              <Product
                item={x}
                id={x._id}
                title={x.title}
                category={x.category}
                description={x.description}
              />
            }
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderProduct;
