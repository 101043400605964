import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, useToast } from "@chakra-ui/react";
import axios from "axios";
import emptyCart from "../../assets/images/emptyCart.png";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { ImCross } from "react-icons/im";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { wishListData } from "../../redux/WishListReducer/WishListReducer";

const Cart = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const token = useSelector((store) => store.auth.token);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(wishListData(token));
  }, [dispatch]);

  const wishData = useSelector((store) => store.wishList?.data?.data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const removeProduct = async (id) => {
    try {
      await axios.delete(`https://api.swapit.co.in/wish/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast({
        title: "Product removed",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting product:", error);
      toast({
        title: "Error removing product",
        description: "An error occurred while removing the product.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="max-w-container mx-auto px-4 ">
      <Breadcrumbs title="Swaplist" />
      {loading ? (
        <p className="h-full w-full flex items-center justify-center p-10">
          <Spinner size="sm" />
        </p>
      ) : wishData?.length > 0 ? (
        <div className="pb-20">
          <div className="mt-5 grid md:grid-cols-5 sm:grid-cols-1 gap-2">
            {wishData?.map(({ _id, productID }) => (
              <div key={_id} className="w-full border py-2">
                <div className="flex flex-col justify-center items-center gap-4">
                  <ImCross
                    onClick={() => removeProduct(productID)}
                    className="text-primeColor hover:text-red-500 duration-300 cursor-pointer"
                  />
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/images/${productID}-0`}
                    className="w-full h-32 object-contain shadow"
                    alt="productImage"
                  />
                  <div className="">
                    <button
                      onClick={() => navigate(`/product/${productID}`)}
                      className="px-4 py-1 text-sm text-green-600 font-semibold rounded-full border border-green-200 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
                    >
                      View Product
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
        >
          <div>
            <img
              className="w-80 rounded-lg p-4 mx-auto"
              src={emptyCart}
              alt="emptyCart"
            />
          </div>
          <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
            <h1 className="font-titleFont text-xl font-bold uppercase">
              Your Swaplist feels lonely.
            </h1>
            <p className="text-sm text-center px-10 -mt-2">
              Your Swaplist lives to serve. Give it purpose - fill it with
              books, electronics, videos, etc. and make it happy.
            </p>
            <Link to="/shop">
              <button className="bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300">
                Continue Swapping
              </button>
            </Link>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Cart;
