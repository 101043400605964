import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../home/Products/Product";

const Pagination = ({ productData }) => {
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(0);

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    productData?.slice(indexOfFirstItem, indexOfLastItem) || [];

  const pageCount = Math.ceil((productData?.length || 0) / itemsPerPage);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  return (
    <div>
      <div className="grid overflow-auto md:grid-cols-3 xl:grid-cols-3 gap-4 mdl:gap-4 lg:gap-8">
        {currentItems?.map((x) => (
          <Product
            key={x?._id}
            id={x?._id}
            title={x?.title}
            category={x?.category}
            description={x?.description}
          />
        ))}
      </div>
      <div className="flex flex-col mdl:flex-row justify-end items-center">
        <ReactPaginate
          nextLabel=""
          onPageChange={handlePageClick}
          pageCount={pageCount}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          previousLabel=""
          pageLinkClassName="w-9 h-9 border-[1px] rounded-full border-lightColor hover:border-gray-500 duration-300 flex justify-center items-center"
          pageClassName="mr-6"
          containerClassName="flex rounded-full text-base font-semibold font-titleFont py-10"
          activeClassName="rounded-full bg-black text-white"
        />
      </div>
    </div>
  );
};

export default Pagination;
