import { useState } from "react";
import Footer from "./components/home/Footer/Footer";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import AllRoutes from "./Routes/AllRoutes";
import { useSelector } from "react-redux";
import Review from "./components/Reviews/Review";

function App() {
  // =========== FETCH REDUX DATA START============= //
  const productData = useSelector(
    (state) => state.productData?.productData?.productData?.data
  );

  // log

  // =========== FILTER HEADER DATA START ============= //
  const [initialData, setInitialData] = useState(productData);

  const filteredData = (state) => {
    const filterResult = productData?.filter((curData) => {
      return curData?.state == state;
    });
    setInitialData(filterResult);
  };

  const categoryData = (cate) => {
    const filterData = productData?.filter((curData) => {
      return curData?.category == cate;
    });
    setInitialData(filterData);
  };

  return (
    <div className="font-bodyFont">
      <Header filteredData={filteredData} />
      {window.location.href.includes("signin", "signup") ? (
        <>
          <HeaderBottom cate={categoryData} />
        </>
      ) : (
        <HeaderBottom cate={categoryData} />
      )}

      <AllRoutes data={initialData} />
      <Footer />
      <Review />

      <img
        className="fixed h-20 w-20 z-50 object-cover rounded-full md:bottom-6 sm:bottom-16 right-2"
        src="https://png.pngtree.com/png-clipart/20210311/original/pngtree-whatsapp-icon-whatsapp-logo-free-logo-design-template-png-image_5990837.png"
      />
    </div>
  );
}

export default App;
