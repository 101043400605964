import React, { useEffect, useState } from "react";
import Banner from "../../components/Banner/Banner";
import ListProducts from "../../components/home/ListProducts/ListProducts";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppData } from "../../redux/AppReducer/Action";
import SliderProduct from "../../components/home/Slider/SliderProduct";
import { Spinner } from "@chakra-ui/react";
import ProductsGrig from "../../components/home/ProductsGrid/ProductsGrig";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import "./Home.css";
const Home = ({ data }) => {
  // <<<<<<<<<<<<<<<<<<< STATES >>>>>>>>>>>>>>>>>>>>>>> //
  const token = useSelector((store) => store.auth.token);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  // <<<<<<<<<<<<<<<<<<< FETCHING DATA FROM API >>>>>>>>>>>>>>>>>>>>>>> //
  useEffect(() => {
    dispatch(fetchAppData());
  }, [dispatch]);

  // <<<<<<<<<<<<<<<<<<<<<< PRELOADER >>>>>>>>>>>>>>>>>>>>>>>>>>>>>> //
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 400);

    return () => clearTimeout(timer);
  }, [data]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spinner size="md" />
      </div>
    );
  }



  // const arr 

  return (
    <div className="w-full mx-auto bg-[#f5f5f3]">
      <div className="">
        <Banner />
      </div>
      <br />
      <div className="max-w-container mx-auto px-4 sm:px-2">
        <SliderProduct data={data} />
      </div>

      <div className="p-4">
        <div className="flex justify-between">
          <div className="border-b-2 border-sky-500 font-titleFont">
            Top <span className="text-sky-500">Electronic Brands</span>
          </div>

          <div className="flex items-center justify-center">
            <span className="text-[14px] font-titleFont">View All</span>
            <span>
              <MdOutlineKeyboardArrowRight className="text-sky-500" />
            </span>
          </div>
        </div>

        <div className="py-5 grid sm:grid-cols-1 gap-2 overflow-auto">
          <div class="OfferCard__OfferCardContainer-sc-1f27jzb-0 iEQPzt">
            <div class="OfferCard__OfferCardLeftSection-sc-1f27jzb-1 imBptA">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRRV9O8IKL0fCUh2D26rXHPceV_7Zu_eUDnGw&usqp=CAU"
                alt="OfferImage"
                class="OfferCard__OfferImg-sc-1f27jzb-2 cwqblp"
              />
              <div class="OfferCard__ConditionApplyText-sc-1f27jzb-3 WAbSq"></div>
            </div>
            <div class="OfferCard__OfferCardRightSection-sc-1f27jzb-4 jbPWCT">
              <div class="OfferCard__BusinessUnitText-sc-1f27jzb-5 gJgZBc">
                Smartphones
              </div>
              <div class="OfferCard__OfferTitle-sc-1f27jzb-6 vOLKx">
                Swap by Categories
              </div>

              <div class="OfferCard__OfferCodeSection-sc-1f27jzb-10 jJltku">
                <div class="OfferCard__OfferCode-sc-1f27jzb-11 iWrqRd">
                  View
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-container mx-auto px-4 py-4 sm:px-2">
        <div className="flex  justify-between">
          <div className="border-b-2 border-sky-500 font-titleFont">
            Shop By <span className="text-sky-500">Top Categories</span>
          </div>

          <div className="flex items-center justify-center">
            <span className="text-[14px] font-titleFont">View All</span>
            <span>
              <MdOutlineKeyboardArrowRight className="text-sky-500" />
            </span>
          </div>
        </div>

        <ProductsGrig />
      </div>

      <div className="max-w-container mx-auto px-4 sm:px-2">
        <div
          className="py-4"
          style={{ boxShadow: "rgba(17, 17, 26, 0.1) 0px 1px 0px" }}
        >
          <div className="text-[25px] font-bold tracking-wide">Featured</div>
        </div>
        <ListProducts data={data} />
      </div>

      <div className="p-4"></div>
      <br />

      <div className="max-w-container mx-auto px-4 sm:px-2">
        <SliderProduct data={data} />
      </div>
    </div>
  );
};

export default Home;
