import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { Spinner } from "@chakra-ui/react";

const SPINNER_SIZE = "md";

const ListProducts = ({ data }) => {
  const reversedArr = data ? [...data].reverse() : [];
  const itemsPerPage = 8;
  const [visibleItems, setVisibleItems] = useState(itemsPerPage);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    if (reversedArr) {
      setLoading(false);
    }
  }, [reversedArr]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    setTimeout(() => {
      setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
      setLoadingMore(false);
    }, 1000);
  };

  const renderProducts = () =>
    reversedArr?.slice(0, visibleItems).map((x) => (
      <div key={Math.random()} className="px-2">
        <Product
          item={x}
          id={x?._id}
          title={x?.title}
          category={x?.category}
          description={x?.description}
        />
      </div>
    ));

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <Spinner size={SPINNER_SIZE} />
      </div>
    );
  }

  return (
    <div className="w-full md:pb-20 sm:pb:10 pt-4">
      <Heading heading="" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 md:gap-2 sm:gap-4">
        {renderProducts()}
      </div>
      {visibleItems < reversedArr?.length && (
        <div className="flex justify-center items-center m-4">
          {loadingMore ? (
            <Spinner size={SPINNER_SIZE} />
          ) : (
            <button
              onClick={handleLoadMore}
              className="bg-[#000] text-[17px] font-bold tracking-wide text-white py-2 px-8"
            >
              Load Collection
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ListProducts;
