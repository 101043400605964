import React from "react";
import { Helmet } from "react-helmet-async";
// SEO
const HeaderSEO = ({
  title,
  description,
  ogImage,
  name,
  type,
  noIndex = false,
}) => {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:site_name" content="Swapit" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:alt" content={description}></meta>
      <meta property="og:url" content={window.location.href} />
      <meta property="fb:app_id" content="" />
      <link rel="canonical" href={window.location.href} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={"Swapit"} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
      {noIndex ? <meta name="robots" content="noindex" /> : null}
    </Helmet>
  );
};

export default HeaderSEO;
