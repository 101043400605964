import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MyPosts = () => {
  const productData = useSelector(
    (state) => state.productData?.productData?.productData?.data
  );
  const user = useSelector((store) => store.auth.user);
  const filteredData = productData?.filter((x) => x.userID === user._id);
  console.log(filteredData);
  const navigate = useNavigate();

  return (
    <div className="p-4 md:h-[75vh] sm:h-[100vh] w-full overflow-auto">
      <div className="">
        <div className="font-titleFont w-full font-bold tracking-bold p-4 flex md:justify-center sm:justify-start">
          My Posts
        </div>
      </div>
      <div className="grid md:grid-cols-4 sm:grid-cols-1 gap-2">
        {filteredData?.map((x) => {
          return (
            <div
              key={Math.random()}
              className="py-8 px-8 w-full mx-auto bg-white rounded-xl  sm:py-4 flex flex-col gap-2"
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
              }}
            >
              <div className="w-full flex items-center justify-center">
                <div className="flex w-full rounded-md justify-center items-center cursor-pointer shadow overflow-auto">
                  <img
                    className="block mx-auto h-24 rounded-md sm:mx-0 sm:shrink-0"
                    src={`${process.env.REACT_APP_BACKEND_URL}/images/${x._id}-0`}
                    alt="Product Image"
                  />
                </div>
              </div>
              <div className=" flex flex-col gap-2">
                <button
                  onClick={() => navigate(`/product/${x._id}`)}
                  className="px-4 py-1 text-sm text-green-600 font-semibold rounded-full border border-green-200 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
                >
                  View
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MyPosts;
