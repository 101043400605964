import React, { useState } from "react";
import axios from "axios";
import { Spinner, useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { states } from "country-cities";
import { cities } from "country-cities";
import { categoryData } from "../../constants";
import {
  Box,
  Text,
  Input,
  Flex,
  Image,
  Tag,
  TagLabel,
  TagCloseButton,
} from "@chakra-ui/react";

const ProductPost = () => {
  const toast = useToast();
  const [title, setTitle] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [category, setCategory] = useState("");
  const [errTitle, setErrTitle] = useState("");
  const [errState, setErrState] = useState("");
  const [errCity, setErrCity] = useState("");
  const [errDescription, setErrDescription] = useState("");
  const [errCategory, setErrCategory] = useState("");
  const [genericError, setGenericError] = useState("");
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [isoState, setIsoState] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchedStates = states.getByCountry("IN");
    setAllStates(fetchedStates);
  }, []);

  useEffect(() => {
    const fetchedCities = cities.getByState(isoState, "IN");
    setAllCities(fetchedCities);
  }, [isoState]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    setErrTitle("");
    setGenericError("");
  };

  const handleStateChange = (e) => {
    const selectedIsoState = e.target.value.split(":")[0];
    const selectedState = e.target.value.split(":")[1];
    setState(selectedState);
    setCity("");
    setErrState("");
    setErrCity("");
    setGenericError("");
    setIsoState(selectedIsoState);
  };

  const handleCityChange = (e) => {
    const selectedCity = e.target.value.split(":")[0];
    setCity(selectedCity);
    setErrCity("");
    setGenericError("");
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setErrDescription("");
    setGenericError("");
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setErrCategory("");
    setGenericError("");
  };

  const handleImagesChange = (e) => {
    const files = Array.from(e.target.files);
    const selectedImages = files.slice(0, 4);
    setImages(selectedImages);
  };

  const handleRemoveImage = (index) => {
    setImages((prevImages) => [
      ...prevImages.slice(0, index),
      ...prevImages.slice(index + 1),
    ]);
  };
  const token = useSelector((store) => store.auth.token);

  const handleProductPost = async (e) => {
    e.preventDefault();
    if (
      !title ||
      !state ||
      !city ||
      !category ||
      !description ||
      images.length === 0
    ) {
      setGenericError("Please fill in all the required fields.");
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", title);
      formData.append("state", state);
      formData.append("city", city);
      formData.append("category", category);
      formData.append("description", description);
      images.forEach((image, index) => {
        formData.append("myFile", image, `${index}.jpg`);
      });

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/product/create`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          maxRedirects: 0,
        }
      );

      if (response.status === 200) {
        toast({
          title: "Product posted successfully!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        window.location.href = "/";
      }
    } catch (error) {
      console.error("Error in handleProductPost:", error);

      if (error.response && error.response.data) {
        setGenericError(error.response.data.message);
        toast({
          title: `Error: ${error.response.data.message}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        setGenericError("An error occurred while processing your request.");

        toast({
          title: "An error occurred. Please try again later.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-container mx-auto p-4 flex justify-center overflow-auto md:h-[75vh] sm:h-[100vh]">
      <form className="md:pb-6 sm:pb-10 w-full">
        <h1 className="font-titleFont pb-4 font-semibold text-3xl">
          Post Product
        </h1>
        <div className="w-full grid md:grid-cols-2 sm:grid-cols-1 gap-6">
          <div className="w-[95%] overflow-hidden">
            <Text fontSize="base" fontWeight="semibold" pl={2}>
              Product Images
            </Text>
            <input
              onChange={handleImagesChange}
              type="file"
              accept="image/*"
              multiple
              className="py-1 text-base font-medium outline-none "
            />
            <Flex mt={2}>
              {images.map((image, index) => (
                <Tag
                  key={index}
                  size="md"
                  variant="solid"
                  colorScheme="teal"
                  cursor="pointer"
                  mr={2}
                >
                  <TagLabel>
                    <Image
                      src={URL.createObjectURL(image)}
                      alt={`Preview ${image.name}`}
                      boxSize="4rem"
                      objectFit="cover"
                      rounded="md"
                    />
                  </TagLabel>
                  <TagCloseButton onClick={() => handleRemoveImage(index)} />
                </Tag>
              ))}
            </Flex>
          </div>
          <div>
            <p className="text-base font-titleFont font-semibold px-2">
              Product Name
            </p>
            <input
              onChange={handleTitleChange}
              value={title}
              className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
              type="text"
              placeholder="Enter the product name here"
            />
            {errTitle && (
              <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                <span className="text-sm italic font-bold">!</span>
                {errTitle}
              </p>
            )}
          </div>
          <div>
            <p className="text-base font-titleFont font-semibold px-2">State</p>
            <select
              onChange={handleStateChange}
              className="w-full py-1 border-b-2 px-2 text-base font-medium outline-none focus-within:border-primeColor"
            >
              <option value="" disabled>
                Select the state here
              </option>
              {allStates.map((state) => (
                <option
                  key={state.isoCode}
                  value={`${state.isoCode}:${state.name}`}
                >
                  {state.name}
                </option>
              ))}
            </select>
            {errState && (
              <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                <span className="text-sm italic font-bold">!</span>
                {errState}
              </p>
            )}
          </div>
          <div>
            <p className="text-base font-titleFont font-semibold px-2">City</p>
            <select
              onChange={handleCityChange}
              value={city}
              className="w-full py-1 border-b-2 px-2 text-base font-medium outline-none focus-within:border-primeColor"
            >
              <option value="" disabled>
                Select the city here
              </option>
              {allCities.map((city) => (
                <option key={city.name} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>
            {errCity && (
              <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                <span className="text-sm italic font-bold">!</span>
                {errCity}
              </p>
            )}
          </div>
          <div>
            <p className="text-base font-titleFont font-semibold px-2">
              Category
            </p>
            <select
              onChange={handleCategoryChange}
              value={category}
              className="w-full py-1 border-b-2 px-2 text-base font-medium outline-none focus-within:border-primeColor"
            >
              <option value="" disabled>
                Select the category here
              </option>
              {categoryData.map((x) => (
                <option key={x.id} value={x.title}>
                  {x.title}
                </option>
              ))}
            </select>
            {errCategory && (
              <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                <span className="text-sm italic font-bold">!</span>
                {errCategory}
              </p>
            )}
          </div>
          <div>
            <p className="text-base font-titleFont font-semibold px-2">
              Product Description
            </p>
            <textarea
              onChange={handleDescriptionChange}
              value={description}
              className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
              placeholder="Enter the product description here"
            />
            {errDescription && (
              <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                <span className="text-sm italic font-bold">!</span>
                {errDescription}
              </p>
            )}
          </div>

          <div className="pb-8">
            <button
              onClick={handleProductPost}
              className="w-full rounded-md bg-primeColor text-gray-200 h-10 font-titleFont text-base tracking-wide font-semibold hover:bg-black hover:text-white duration-200"
            >
              {loading ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              ) : (
                "Post Product"
              )}
            </button>
          </div>

          {genericError && (
            <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
              <span className="text-sm italic font-bold">!</span>
              {genericError}
            </p>
          )}
        </div>
      </form>
    </div>
  );
};

export default ProductPost;
