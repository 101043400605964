// action.js
import axios from "axios";
import * as types from "./ActionTypes";

const getAppDataRequest = () => ({
  type: types.FETCH_APP_DATA_REQUEST,
});

const getAppDataSuccess = (data) => ({
  type: types.FETCH_APP_DATA_SUCCESS,
  payload: data,
});

const getAppDataFailure = (error) => ({
  type: types.FETCH_APP_DATA_FAILURE,
  payload: error,
});

export const fetchDataFromApi = async () => {
  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/product`;
    const response = await axios.get(apiUrl);

    return response.data;
  } catch (error) {
    console.error("Error fetching data from the API:", error);
    throw error;
  }
};

export const fetchAppData = () => async (dispatch) => {
  try {
    dispatch(getAppDataRequest());

    const data = await fetchDataFromApi();

    dispatch(getAppDataSuccess({ productData: data }));
  } catch (error) {
    console.error("Error fetching app data:", error);
    dispatch(getAppDataFailure(error));
  }
};
