import { applyMiddleware, combineReducers, legacy_createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { thunk } from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import { encryptTransform } from "./enc";
import storage from "redux-persist/lib/storage";
import { Reducer as AppReducer } from "./AppReducer/Reducer";
import { Reducer as AuthReducer } from "./AuthReducer/Reducer";
import fetchAdminProduct from "./adminProductReducer/adminFetchedData";
import WishListReducer from "./WishListReducer/WishListReducer";
import reciveExchangeDataReducer from "./ExchangeReducer/ReciveExchangeSlice";
const persistConfig = {
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_STATE_ENC,
      onError: function (error) {
        console.log(error);
      },
    }),
  ],

  key: "persist-key",
  // storage:storageSession
  storage,

  stateReconciler: (inboundState, originalState) => {
    if (originalState.app) {
      return {
        ...inboundState,
        app: {
          ...inboundState.app,
        },
      };
    }
    if (originalState.auth) {
      return {
        ...inboundState,
        auth: {
          ...inboundState.auth,
        },
      };
    }
    return inboundState;
  },
};

const RootReducer = combineReducers({
  productData: AppReducer,
  auth: AuthReducer,
  adminProduct: fetchAdminProduct,
  wishList: WishListReducer,
  reciveExchangeData: reciveExchangeDataReducer,
});

const persistedReducer = persistReducer(persistConfig, RootReducer);
const store = legacy_createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const persistedStore = persistStore(store);
export { store };
export { persistedStore };
