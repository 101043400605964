import React, { useState } from "react";
import { MdFavorite } from "react-icons/md";
import { PiSwapBold } from "react-icons/pi";
import { useToast } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { MdReportGmailerrorred } from "react-icons/md";
import Report from "../../../components/pageProps/productDetails/Report";

const ProductInfo = ({ productInfo, setdisplayPopUp }) => {
  const toast = useToast();
  const token = useSelector((store) => store.auth.token);
  const id = productInfo?._id;
  const [report, setReport] = useState(false);
  const handelAddToWishlist = async () => {
    try {
      const authToken = `Bearer ${token}`;
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/wish/create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
          body: JSON.stringify({
            productID: id,
          }),
        }
      );

      if (response.status === 301) {
        // Handle the redirect manually
        const redirectedUrl = response.headers.get("Location");
        if (redirectedUrl) {
          const redirectedResponse = await fetch(redirectedUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: authToken,
            },
            body: JSON.stringify({
              productID: id,
            }),
          });

          if (!redirectedResponse.ok) {
            throw new Error(`HTTP error! Status: ${redirectedResponse.status}`);
          }

          toast({
            title: "Added to wishlist",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          throw new Error("Redirected URL not found");
        }
      } else if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {
        toast({
          title: "Added to wishlist",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error.message);
      toast({
        title: "Error adding to wishlist",
        description: "Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="relative">
      <div className="flex flex-col gap-5">
        <h2 className="text-4xl font-semibold flex justify-between items-center">
          {productInfo?.title}
          <div
            onClick={() => setReport(true)}
            className="text-base text-red-500 cursor-pointer flex items-center"
          >
            <MdReportGmailerrorred />
            report
          </div>
        </h2>
        <p className="text-base text-gray-600">{productInfo?.description}</p>
        <div className="grid-2--cols grid grid-cols-2 gap-3">
          <button
            onClick={() => setdisplayPopUp(true)}
            className="flex items-center cursor-pointer justify-center gap-2 border-2 border-[#000]  font-bold text-[#fff] py-3 w-full transition duration-300 ease-in-out bg-[#000] mr-6"
          >
            Swap Item
            <span>
              {" "}
              <PiSwapBold />
            </span>
          </button>

          <button
            onClick={() => handelAddToWishlist()}
            className="flex items-center cursor-pointer justify-center gap-2 border-2 border-[#000] font-bold text-[#000] py-3 w-full transition duration-300 ease-in-out hover:bg-[#000] hover:text-white mr-6"
          >
            Add to wishlist
            <span>
              <MdFavorite />
            </span>
          </button>
        </div>

        <p className="font-normal text-sm">
          <span className="text-base font-medium"> Categories:</span>{" "}
          {productInfo?.category}
        </p>
        <p className="font-normal text-sm">
          <span className="text-base font-medium"> State :</span>{" "}
          {productInfo?.state}
        </p>
        <p className="font-normal text-sm">
          <span className="text-base font-medium"> City :</span>{" "}
          {productInfo?.city}
        </p>
      </div>

      {report === true ? <Report setReport={setReport} /> : null}
    </div>
  );
};

export default ProductInfo;
