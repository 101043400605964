// AppReducer.js
import * as types from "./ActionTypes";

const initialState = {
  productData: [],
  isLoading: false,
  isError: false,
};

export const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.FETCH_APP_DATA_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case types.FETCH_APP_DATA_SUCCESS:
      return {
        ...state,
        productData: payload,
        isLoading: false,
        isError: false,
      };
    case types.FETCH_APP_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        productData: {},
        isError: true,
      };
    default:
      return state;
  }
};
