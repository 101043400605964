import React, { useState } from "react";
import { motion } from "framer-motion";
import { ImPlus } from "react-icons/im";
import FilterCategoryList from "./FilterCategoryList";
const CategoryList = ({ title }) => {
  const [showSubCatOne, setShowSubCatOne] = useState(false);

  return (
    <li>
      <div className="border-b-[1px] border-b-[#F0F0F0] pb-2 font-medium tracking-wide flex items-center justify-between">
        {title}
        <span
          onClick={() => setShowSubCatOne(!showSubCatOne)}
          className="text-[10px] lg:text-xs cursor-pointer text-gray-400 hover:text-primeColor duration-300"
        >
          <ImPlus />
        </span>
      </div>

      {showSubCatOne && (
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <FilterCategoryList title={title} />
        </motion.div>
      )}
    </li>
  );
};

export default CategoryList;
