import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const reciveExchangeData = createAsyncThunk(
  "reciveExchangeData",
  async (token) => {
    try {
      const response = await axios.get(
        `https://api.swapit.co.in/exchange/allsendrequest`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching admin product data:", error);
      throw error;
    }
  }
);

const reciveExchangeDataSlice = createSlice({
  name: "exchangeData",
  initialState: {
    isLoading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder
      .addCase(reciveExchangeData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(reciveExchangeData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(reciveExchangeData.rejected, (state, action) => {
        console.error(
          "Error fetching admin product data:",
          action.error.message
        );
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default reciveExchangeDataSlice.reducer;
