import React, { useState } from "react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import Pagination from "../../components/pageProps/shopPage/Pagination";
import ProductBanner from "../../components/pageProps/shopPage/ProductBanner";
import ShopSideNav from "../../components/pageProps/shopPage/ShopSideNav";
import { useSelector } from "react-redux";

const Shop = () => {
  const productData = useSelector(
    (state) => state.productData?.productData?.productData?.data
  );

  const [itemsPerPage, setItemsPerPage] = useState(12);
  const itemsPerPageFromBanner = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
  };

  const [initialData, setInitialData] = useState(productData);

  const filterState = (location) => {
    const filterData = productData.filter((item) => item.state === location);
    setInitialData(filterData);
  };

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Store" />
      {/* ================= Products Start here =================== */}
      <div className="w-full h-full flex pb-0 gap-10">
        <div className="w-[20%] lgl:w-[25%] hidden mdl:inline-flex md:h-[100vh] sm:h-full overflow-auto">
          <ShopSideNav filterState={filterState} />
        </div>
        <div className="w-full mdl:w-[80%] lgl:w-[75%] md:h-[100vh] sm:h-full overflow-auto flex flex-col gap-10">
          <ProductBanner
            itemsPerPage={itemsPerPage}
            itemsPerPageFromBanner={itemsPerPageFromBanner}
          />
          <Pagination productData={initialData} />
        </div>
      </div>
      {/* ================= Products End here ===================== */}
    </div>
  );
};

export default Shop;
