import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import { states, cities } from "country-cities";
import { GoTriangleDown } from "react-icons/go"; // Make sure to import GoTriangleDown

const Signup = () => {
  const [isoState, setIsoState] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [errState, setErrState] = useState("");
  const [errCity, setErrCity] = useState("");
  const [genericError, setGenericError] = useState("");
  const [allStates, setAllStates] = useState([]);
  const [allCities, setAllCities] = useState([]);

  //================================//
  const [name, setname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const [mobile, setmobile] = useState("");

  // ========== Error Messages Start here ============
  const [errname, setErrname] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [errGender, setErrGender] = useState("");
  const [errmobile, setErrmobile] = useState("");
  // ========== Error Messages End here ==============
  const [successMsg, setSuccessMsg] = useState("");
  const [loading, setLoading] = useState(false); // New state for loading
  const navigate = useNavigate();

  useEffect(() => {
    const fetchedStates = states.getByCountry("IN");
    setAllStates(fetchedStates);
  }, []);

  useEffect(() => {
    const fetchedCities = cities.getByState(isoState, "IN");
    setAllCities(fetchedCities);
  }, [isoState]);

  const handleStateChange = (e) => {
    const selectedIsoState = e.target.value.split(":")[0];
    const selectedState = e.target.value.split(":")[1];
    setState(selectedState);
    setCity("");
    setErrState("");
    setErrCity("");
    setGenericError("");
    setIsoState(selectedIsoState);
  };

  const handleCityChange = (e) => {
    const selectedCity = e.target.value.split(":")[0];
    setCity(selectedCity);
    setErrCity("");
    setGenericError("");
  };

  const handleName = (e) => {
    setname(e.target.value);
    setErrname("");
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };

  const handleGender = (e) => {
    setGender(e.target.value);
    setErrGender("");
  };

  const handlemobile = (e) => {
    setmobile(e.target.value);
    setErrmobile("");
  };
  // Email Validation function...
  const EmailValidation = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    if (!name) {
      setErrname("Enter your Name");
    }

    if (!email) {
      setErrEmail("Enter your Email");
    } else {
      if (!EmailValidation(email)) {
        setErrEmail("Enter a Valid Email");
      }
    }

    if (!password) {
      setErrPassword("Enter your Password");
    }

    if (!state) {
      setErrState("Enter your State");
    }

    if (!city) {
      setErrCity("Enter your City");
    }

    if (!gender) {
      setErrGender("Select your Gender");
    }

    if (!mobile) {
      setErrmobile("Enter your Phone Number");
    }

    if (
      name &&
      email &&
      EmailValidation(email) &&
      password &&
      state &&
      city &&
      gender &&
      mobile
    ) {
      try {
        setLoading(true);

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/signup`,
          {
            name,
            email,
            password,
            state,
            city,
            gender,
            mobile,
          }
        );

        setSuccessMsg(`Registration successful for ${name}`);
        navigate("/signin");
      } catch (error) {
        console.error("Error during signup:", error.message);
        setGenericError("Registration failed. Please try again.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="max-w-container mx-auto px-4 py-8">
      {successMsg ? (
        <p className="pb-20 w-full font-medium text-green-500">{successMsg}</p>
      ) : (
        <form className="pb-6">
          <h1 className="font-titleFont text-center font-semibold text-3xl">
            Sign Up
          </h1>
          <div className="w-full max-w-[500px] mx-auto py-6 flex flex-col gap-6">
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Name
              </p>
              <input
                onChange={handleName}
                value={name}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Enter your name here"
              />
              {errname && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errname}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Email
              </p>
              <input
                onChange={handleEmail}
                value={email}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="email"
                placeholder="Enter your email here"
              />
              {errEmail && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errEmail}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Phone Number
              </p>
              <input
                onChange={handlemobile}
                value={mobile}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="text"
                placeholder="Enter your phone number here"
              />
              {errmobile && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errmobile}
                </p>
              )}
            </div>
            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Gender
              </p>
              <select
                onChange={handleGender}
                value={gender}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
              >
                <option value="" disabled>
                  Select your gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              {errGender && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errGender}
                </p>
              )}
            </div>

            <div className="w-full flex items-center gap-2 md:gap-6 mb-4">
              <div className="flex w-full items-center gap-2 text-base text-[#767676] relative">
                <select
                  onChange={handleStateChange}
                  id="countries"
                  className="w-full border-[1px] border-gray-200 py-1 px-4 cursor-pointer text-primeColor text-base block dark:placeholder-gray-400 appearance-none focus-within:outline-none focus-visible:border-primeColor"
                >
                  <option value="">State</option>
                  {allStates.map((state) => (
                    <option
                      key={state.isoCode}
                      value={`${state.isoCode}:${state.name}`}
                    >
                      {state.name}
                    </option>
                  ))}
                </select>
                <span className="absolute text-sm right-2 md:right-4 top-2.5">
                  <GoTriangleDown />
                </span>
              </div>
            </div>

            <div className="w-full flex items-center gap-2 text-base text-[#767676] relative">
              <select
                onChange={handleCityChange}
                id="countries"
                className=" w-full border-[1px] border-gray-200 py-1 px-4 cursor-pointer text-primeColor text-base block dark:placeholder-gray-400 appearance-none focus-within:outline-none focus-visible:border-primeColor"
              >
                <option value="">City</option>
                {allCities.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
              <span className="absolute text-sm right-2 md:right-4 top-2.5">
                <GoTriangleDown />
              </span>
            </div>

            <div>
              <p className="text-base font-titleFont font-semibold px-2">
                Password
              </p>
              <input
                onChange={handlePassword}
                value={password}
                className="w-full py-1 border-b-2 px-2 text-base font-medium placeholder:font-normal placeholder:text-sm outline-none focus-within:border-primeColor"
                type="password"
                placeholder="Enter your password here"
              />
              {errPassword && (
                <p className="text-red-500 text-sm font-titleFont font-semibold mt-1 px-2 flex items-center gap-1">
                  <span className="text-sm italic font-bold">!</span>
                  {errPassword}
                </p>
              )}
            </div>
            <div className="flex gap-3">
              <button
                onClick={handleSignup}
                className="w-44 bg-primeColor rounded-md text-gray-200 h-10 font-titleFont text-base tracking-wide font-semibold hover:bg-black hover:text-white duration-200"
                disabled={loading}
              >
                {loading ? <Spinner size="sm" /> : "Register"}
              </button>

              <Link to="/signin">
                <button
                  className="w-44 rounded-md bg-primeColor text-gray-200 h-10 font-titleFont text-base tracking-wide font-semibold hover:bg-black hover:text-white duration-200"
                  disabled={loading}
                >
                  Sign In
                </button>
              </Link>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default Signup;
