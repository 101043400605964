import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children }) => {
  const user = useSelector((store) => store.auth.user);
  return user.email ? children : <Navigate to="/signin" />;
};

export default PrivateRoute;
