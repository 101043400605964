import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Spinner, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const SwapRequest = () => {
  const token = useSelector((store) => store.auth.token);
  const navigate = useNavigate();
  const [reciveData, setReciveData] = useState(null);
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  const apiUrl = "https://api.swapit.co.in/exchange/allsendrequest";
  const fetchData = async () => {
    try {
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setTimeout(() => {
        setReciveData(response.data.data);
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response?.data || error.message
      );
      throw error;
    }
  };
  useEffect(() => {
    fetchData();
  }, [token]);

  const handleAccept = async (productID) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/exchange/update/${productID}`,
        {
          status: "accept",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast({
        title: "Product Accepted successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      console.log("Accept success:", response.data);
    } catch (error) {
      toast({
        title: "Error accepting product",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      console.error("Accept error:", error);
    }
  };

  const handleReject = async (productID) => {
    console.log(productID);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/exchange/update/${productID}`,
        {
          status: "reject",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast({
        title: "Product Rejected successfully!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      console.log("Reject success:", response.data);
    } catch (error) {
      toast({
        title: "Error rejecting product",
        status: "error",
        duration: 3000,
        isClosable: true,
      });

      console.error("Reject error:", error);
    }
  };
  return (
    <div className="flex flex-col gap-4 h-[75vh] overflow-auto p-4">
      {loading && (
        <div className="flex justify-center items-center h-full">
          <Spinner size="sm" />
        </div>
      )}
      {!loading && (
        <>
          <div className="grid grid-cols-2 gap-4 text-center"></div>

          <div className="md:flex sm:none items-center justify-center">
            <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
              {reciveData?.map((x) => {
                return (
                  <div
                    key={x?._id}
                    className="grid grid-cols-3 gap-2 p-4"
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    }}
                  >
                    <img
                      height="100"
                      width="100"
                      onClick={() => navigate(`/product/${x.fromProductID}`)}
                      className="cursor-pointer shadow-sm h-[100px] object-contain"
                      src={`${process.env.REACT_APP_BACKEND_URL}/images/${x?.fromProductID}-0`}
                      alt=""
                    />

                    {x.status === "accept" ? (
                      <div className="flex flex-col items-center justify-center gap-2">
                        <button
                          onClick={() => navigate(`/view/${x.fromProductID}`)}
                          className="px-4 py-1 text-sm text-green-600 font-semibold rounded-full border border-green-200 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
                        >
                          View
                        </button>
                      </div>
                    ) : (
                      <div className="flex flex-col items-center justify-between gap-1">
                        <button
                          className="px-4 py-1 text-sm text-green-600 font-semibold rounded-full border border-green-200 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2"
                          onClick={() => handleAccept(x?._id)}
                        >
                          Accept
                        </button>
                        <button
                          className="px-5 py-1 text-sm text-red-600 font-semibold rounded-full border border-red-200 hover:text-white hover:bg-red-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
                          onClick={() => handleReject(x?._id)}
                        >
                          Reject
                        </button>
                      </div>
                    )}

                    <img
                      height="100"
                      width="100"
                      onClick={() => navigate(`/product/${x.toProductID}`)}
                      className="cursor-pointer h-[100px] object-contain"
                      src={`${process.env.REACT_APP_BACKEND_URL}/images/${x.toProductID}-0`}
                      alt=""
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SwapRequest;
