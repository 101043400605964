import React, { useState } from "react";
import NavTitle from "./NavTitle";
import CategoryList from "./CategoryList";
import { categoryData } from "../../../../constants";

const Category = () => {
  return (
    <div className="w-full">
      <NavTitle title="Shop by Category" icons={false} />
      <div>
        <ul className="flex flex-col gap-4 text-sm lg:text-base text-[#000]">
          {categoryData.map((x) => {
            return <CategoryList key={x.title} title={x.title}  />;
          })}
        </ul>
      </div>
    </div>
  );
};

export default Category;
