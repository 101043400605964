import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminRoute = ({ children }) => {
  const user = useSelector((store) => store.auth.user);

  return user.administration === "admin" ? children : <Navigate to="/signin" />;
};

export default AdminRoute;
