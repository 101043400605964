import "./Profile.css";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout as logoutAction } from "../../redux/AuthReducer/Action";
import Userdetails from "./ProfileContent/Userdetails";
import Post from "../Post/Post";
import { useToast } from "@chakra-ui/react";
import { CgLayoutList } from "react-icons/cg";
import Admin from "../Admin/Admin";
import { IoMdAdd } from "react-icons/io";
import SwapRequest from "./ProfileContent/SwapRequest";
import MyPosts from "./ProfileContent/MyPosts";
import { MdOutlineSwapHoriz } from "react-icons/md";
const Profile = () => {
  const [activeTab, setActiveTab] = useState("user");
  const user = useSelector((store) => store.auth.user);
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleLogout = () => {
    try {
      dispatch(logoutAction(toast, navigate));
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case "user":
        return <Userdetails handleLogout={handleLogout} />;

      case "lock":
        return (
          <div>
            {" "}
            <Admin />
          </div>
        );
      case "folder":
        return (
          <div>
            <Post />
          </div>
        );
      case "list":
        return (
          <div>
            <MyPosts />
          </div>
        );
      case "body":
        return (
          <div>
            {" "}
            <SwapRequest />
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="body ">
      <nav className="sidebar close">
        <div className="menu-bar">
          <ul className="menu-links">
            <li title="User" className={activeTab === "user" ? "active" : ""}>
              <div onClick={() => tabChange("user")}>
                <i className="bx bx-user icon"></i>
              </div>
            </li>

            {user.administration === "admin" && (
              <li
                title="Admin"
                className={activeTab === "lock" ? "active" : ""}
              >
                <div onClick={() => tabChange("lock")}>
                  <i className="bx bx-lock icon"></i>
                </div>
              </li>
            )}

            <li title="Post" className={activeTab === "folder" ? "active" : ""}>
              <div onClick={() => tabChange("folder")}>
                <IoMdAdd className="icon text-[10px]" />
              </div>
            </li>

            <li
              title="Post Lists"
              className={activeTab === "list" ? "active" : ""}
            >
              <vid onClick={() => tabChange("list")}>
                <CgLayoutList className="icon text-[12px]" />
              </vid>
            </li>

            <li
              title="Swap Request"
              className={activeTab === "body" ? "active" : ""}
            >
              <div onClick={() => tabChange("body")}>
                <MdOutlineSwapHoriz className="icon text-[10px]" />
              </div>
            </li>

            <li title="Logout" onClick={handleLogout} className="active">
              <div>
                <i className="bx bx-log-out icon"></i>
              </div>
            </li>
          </ul>
        </div>
      </nav>

      <section className="home max-h-[100vh] ">{renderContent()}</section>
    </div>
  );
};

export default Profile;
