import { useParams } from "react-router-dom";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import SliderProduct from "../../components/home/Slider/SliderProduct";
import SwapPopup from "../../components/pageProps/productDetails/SwapPopup";
const ProductDetails = () => {
  const [displayPopUp, setdisplayPopUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [productThumbnail, setProductThumbnail] = useState(0);

  const productData = useSelector(
    (state) => state.productData?.productData?.productData?.data
  );

  const id = useParams();
  const paramId = id._id;

  const filterData = productData?.filter((x) => x._id == paramId);
  const filteredData = filterData[0];

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [filteredData]);
  if (loading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Spinner size="md" />
      </div>
    );
  }

  const reverseProduct = productData.reverse();

  return (
    <div className="">
      <div className="w-full mx-auto border-b-[1px]">
        <div className="relative">
          <div className="max-w-container mx-auto">
            <div className="w-full flex items-center md:flex-row sm:flex-col gap-6 px-4 md:py-8 sm:py-4">
              <div className="h-full md:w-[50%] sm:w-full flex md:flex-row sm:flex-col-reverse items-center justify-center gap-1">
                <div className="flex gap-1 md:flex-col overflow-auto">
                  <img
                    onMouseEnter={() => setProductThumbnail(0)}
                    className="w-full h-16 object-contain shadow p-1"
                    src={`${process.env.REACT_APP_BACKEND_URL}/images/${filteredData?._id}-0`}
                    alt="image thumbnail"
                  />
                  <img
                    onMouseEnter={() => setProductThumbnail(1)}
                    className="w-full h-16 object-contain shadow p-1"
                    src={`${process.env.REACT_APP_BACKEND_URL}/images/${filteredData?._id}-1`}
                    alt="image thumbnail"
                  />
                  <img
                    onMouseEnter={() => setProductThumbnail(2)}
                    className="w-full h-16 object-contain shadow p-1"
                    src={`${process.env.REACT_APP_BACKEND_URL}/images/${filteredData?._id}-2`}
                    alt="image thumbnail"
                  />
                  <img
                    onMouseEnter={() => setProductThumbnail(3)}
                    className="w-full h-16 object-contain shadow p-1"
                    src={`${process.env.REACT_APP_BACKEND_URL}/images/${filteredData?._id}-3`}
                    alt="image thumbnail"
                  />
                </div>

                <img
                  className="w-[400px] block h-[300px] object-contain"
                  src={`${process.env.REACT_APP_BACKEND_URL}/images/${filteredData?._id}-${productThumbnail}`}
                />
              </div>

              <div className="h-full md:w-[50%] sm:w-full md:col-span-2 xl:col-span-3 xl:p-14 flex flex-col gap-6 justify-center">
                <ProductInfo
                  productInfo={filteredData}
                  setdisplayPopUp={setdisplayPopUp}
                />
              </div>
            </div>
          </div>

          {displayPopUp === true ? (
            <div
              className="absolute top-0 w-full py-8 px-2 flex items-center justify-center "
              style={{ zIndex: "999" }}
            >
              <div className="md:w-1/2 sm:w-full">
                <SwapPopup setdisplayPopUp={setdisplayPopUp} />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <br />
      <div className="bg-[#f5f5f5]">
        <SliderProduct data={reverseProduct} />
      </div>
    </div>
  );
};

export default ProductDetails;
